import React, { useRef } from 'react';
import { ChartBar, Activity, GitBranch } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/card';
import { Button } from './components/ui/button';
import Header from './Header';
import CTASection from './CTA';

const Homepage = () => {
  const productsRef = useRef(null);
  const teamRef = useRef(null);
  const contactRef = useRef(null);
  const productShowcaseRef = useRef(null);

  // const scrollToSection = (elementRef) => {
  //   elementRef.current.scrollIntoView({ behavior: 'smooth' });
  // };

  const scrollToSection = (elementRef) => {
    const yOffset = -100; // Adjust this value to change the offset
    const element = elementRef.current;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header */}
      <Header 
        scrollToSection={scrollToSection}
        productsRef={productsRef}
        teamRef={teamRef}
        contactRef={contactRef}
      />

      {/* Main Content */}
      <main className="max-w-7xl mx-auto py-20 px-2 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="text-center py-12 bg-gray-300 rounded-lg">
          <h2 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Data-Driven Hedge Fund-Level Quant Insights
          </h2>
          <p className="mt-5 max-w-xl mx-auto text-xl text-gray-500">
            Empowering asset managers with sophisticated tools for superior portfolio performance.
          </p>
          <div className="mt-8 flex justify-center">
            <Button onClick={() => scrollToSection(contactRef)} className="px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-slate-700 hover:bg-blue-700 md:py-4 md:text-lg md:px-10">
            Contact Us
            </Button>
          </div>
        </div>

        <div ref={productsRef} className="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
        <Card className="transition-all duration-300 hover:shadow-lg hover:scale-105 hover:bg-blue-100">
          <CardHeader>
            <CardTitle className="flex items-center">
              <Activity className="h-6 w-6 text-blue-600 mr-2" />
              Risk Management Frameworks
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-gray-500">Advanced risk assessment tools to optimize your portfolio's risk-return profile.</p>
          </CardContent>
        </Card>
        <Card 
          className="transition-all duration-300 hover:shadow-lg hover:scale-105 cursor-pointer hover:bg-blue-100"
          onClick={() => scrollToSection(productShowcaseRef)}
        >
          <CardHeader>
            <CardTitle className="flex items-center">
              <ChartBar className="h-6 w-6 text-blue-600 mr-2" />
              Quant-Based Asset Selection
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-gray-500">Leverage sophisticated algorithms for data-driven asset selection and allocation.</p>
          </CardContent>
        </Card>
        <Card className="transition-all duration-300 hover:shadow-lg hover:scale-105 hover:bg-blue-100">
          <CardHeader>
            <CardTitle className="flex items-center">
              <GitBranch className="h-6 w-6 text-blue-600 mr-2" />
              Backtesting Frameworks
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-gray-500">Robust backtesting tools to validate strategies and optimize performance.</p>
          </CardContent>
        </Card>
      </div>

        {/* Team Section */}
        <div ref={teamRef} className="mt-20 bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">Our Team</h3>
            <p className="text-gray-600 text-justify leading-relaxed">
             We are a dynamic group of graduates from India's premier technical institutions - IIT Kharagpur, IIT Bombay, and IIT Delhi - bringing together diverse expertise in quantitative finance, computer science, and advanced mathematics. Our team members have honed their skills at leading global investment firms including Goldman Sachs, Deutsche Bank, JP Morgan, and World Quant, where they've worked on cutting-edge algorithmic trading strategies, risk management models, and financial software development.<br></br><br></br> This unique blend of academic excellence from IITs and hands-on experience from top-tier financial institutions drives our innovative approach to asset management solutions. We leverage our deep understanding of financial markets, combined with expertise in machine learning and big data analytics, to develop sophisticated quantitative models and trading algorithms. Our collaborative culture fosters continuous learning and innovation, enabling us to stay at the forefront of the rapidly evolving fintech landscape and deliver unparalleled value to our clients.
            </p>
          </div>
        </div>

        {/* Product Showcase */}
        <div ref={productShowcaseRef} className="mt-20 bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">Our Products in Action</h3>
            <div className="aspect-w-16 aspect-h-9">
              <img src="/asset-selection.png" alt="Portfolio Performance Graph" className="object-cover rounded-lg" />
            </div>
            <p className="mt-4 text-gray-600">
              Our quant-based asset selection tools provide deep insights into portfolio performance, asset allocation, and key statistics, enabling data-driven decision making for optimal returns.
            </p>
          </div>
        </div>

        {/* CTA Section */}
        <CTASection />
      </main>

      {/* Footer */}
      <footer ref={contactRef} className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          {/* <nav className="-mx-5 -my-2 flex flex-wrap justify-center">
            <div className="px-5 py-2">
              <a href="#" className="text-base text-gray-500 hover:text-gray-900">About</a>
            </div>
            <div className="px-5 py-2">
              <a href="#" className="text-base text-gray-500 hover:text-gray-900">Blog</a>
            </div>
            <div className="px-5 py-2">
              <a href="#" className="text-base text-gray-500 hover:text-gray-900">Careers</a>
            </div>
            <div className="px-5 py-2">
              <a href="#" className="text-base text-gray-500 hover:text-gray-900">Contact</a>
            </div>
          </nav> */}
          <div className="mt-8 flex justify-center space-x-6">
            {/* Social media icons */}
          </div>
          <p className="mt-8 text-center text-base text-gray-400">
            &copy; 2024 Prakriti AI Technologies Private Limited. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Homepage;