// src/firebaseUtils.js

import { initializeApp } from 'firebase/app';
import { getDatabase, ref, push, set, get } from 'firebase/database';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Check if essential configuration is present
if (!firebaseConfig.databaseURL) {
  throw new Error('Firebase Database URL is missing. Make sure REACT_APP_FIREBASE_DATABASE_URL is set in your environment variables.');
}

if (!firebaseConfig.projectId) {
  throw new Error('Firebase Project ID is missing. Make sure REACT_APP_FIREBASE_PROJECT_ID is set in your environment variables.');
}

let app, database, analytics;

try {
  app = initializeApp(firebaseConfig);
  database = getDatabase(app);
  analytics = getAnalytics(app);
} catch (error) {
  console.error('Error initializing Firebase:', error);
  throw error;
}

// Database Operations
export const submitForm = async (formData) => {
  try {
    const formSubmissionsRef = ref(database, 'formSubmissions');
    const newSubmissionRef = push(formSubmissionsRef);
    await set(newSubmissionRef, {
      ...formData,
      timestamp: Date.now()
    });
    logEvent(analytics, 'form_submission', { form_type: 'CTA' });
    return newSubmissionRef.key;
  } catch (error) {
    console.error('Error submitting form:', error);
    throw error;
  }
};

export const addAdditionalData = async (data) => {
  try {
    const additionalDataRef = ref(database, 'additionalData');
    const newDataRef = push(additionalDataRef);
    await set(newDataRef, {
      ...data,
      timestamp: Date.now()
    });
    logEvent(analytics, 'additional_data_added', { data_type: data.type });
    return newDataRef.key;
  } catch (error) {
    console.error('Error adding additional data:', error);
    throw error;
  }
};

export const getFormSubmissions = async () => {
  try {
    const formSubmissionsRef = ref(database, 'formSubmissions');
    const snapshot = await get(formSubmissionsRef);
    return snapshot.val();
  } catch (error) {
    console.error('Error getting form submissions:', error);
    throw error;
  }
};

export const getAdditionalData = async () => {
  try {
    const additionalDataRef = ref(database, 'additionalData');
    const snapshot = await get(additionalDataRef);
    return snapshot.val();
  } catch (error) {
    console.error('Error getting additional data:', error);
    throw error;
  }
};

// Analytics Operations
export const logCustomEvent = (eventName, eventParams = {}) => {
  try {
    logEvent(analytics, eventName, eventParams);
  } catch (error) {
    console.error('Error logging custom event:', error);
  }
};

export { analytics };