import React, { useState } from 'react';

const Header = ({ scrollToSection, productsRef, teamRef, contactRef }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavClick = (ref) => {
    scrollToSection(ref);
    setIsMenuOpen(false);
  };

  return (
    <header className="bg-white shadow fixed top-0 left-0 right-0 z-10">
      <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <a href="/" className="block">
            <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">Prakriti AI</h1>
          </a>
          
          {/* Hamburger menu for small screens */}
          <button 
            className="md:hidden text-gray-600 hover:text-gray-900"
            onClick={toggleMenu}
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>

          {/* Navigation for medium and large screens */}
          <nav className="hidden md:block">
            <ul className="flex space-x-4">
              <li><button onClick={() => handleNavClick(productsRef)} className="text-gray-600 hover:text-gray-900">Products</button></li>
              <li><button onClick={() => handleNavClick(teamRef)} className="text-gray-600 hover:text-gray-900">Team</button></li>
              <li><button onClick={() => handleNavClick(contactRef)} className="text-gray-600 hover:text-gray-900">Contact</button></li>
            </ul>
          </nav>
        </div>

        {/* Collapsible menu for small screens */}
        {isMenuOpen && (
          <nav className="mt-4 md:hidden">
            <ul className="flex flex-col space-y-2">
              <li><button onClick={() => handleNavClick(productsRef)} className="text-gray-600 hover:text-gray-900 w-full text-left py-2">Products</button></li>
              <li><button onClick={() => handleNavClick(teamRef)} className="text-gray-600 hover:text-gray-900 w-full text-left py-2">Team</button></li>
              <li><button onClick={() => handleNavClick(contactRef)} className="text-gray-600 hover:text-gray-900 w-full text-left py-2">Contact</button></li>
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;