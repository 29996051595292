import React from 'react';
import Homepage from './Homepage';  // Adjust the path if necessary

function App() {
  return (
    <div className="App">
      <Homepage />
    </div>
  );
}

export default App;